const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default {
  startUrl: [
    "/admin/organization/parties",
    "/admin/organization/parties/create",
  ],

  steps: ({ tour, $vue }) => [
    {
      attachTo: { element: "#nav-admin-parties", on: "right" },
      cancelIcon: { enabled: true },
      beforeShowPromise: async () => {
        $vue.$store.commit("setVerticalSidebarDrawer", true);
        await sleep(500);
      },
      showOn: () => $vue.$route.path == "/admin/organization/parties",
      title: "Acesse a aba de eventos.",
      buttons: [
        {
          text: "Próximo",
          primary: true,
          action: function () {
            this.getCurrentStep().hide();
            tour.next();
          },
        },
      ],
      scopeUris: ["/admin/organization/parties"],
    },
    {
      attachTo: { element: "#btn-party-create", on: "bottom" },
      advanceOn: { selector: "#btn-party-create", event: "click" },
      cancelIcon: { enabled: true },
      beforeShowPromise: () => {
        $vue.$store.commit("setVerticalSidebarDrawer", false);
      },
      showOn: () => $vue.$route.path == "/admin/organization/parties",
      title: "Clique no botão para criar evento.",
      scopeUris: [
        "/admin/organization/parties",
        "/admin/organization/parties/create",
      ],
    },
    {
      attachTo: { element: "#form-party-details", on: "bottom" },
      cancelIcon: { enabled: false },
      beforeShowPromise: () => sleep(1000),
      when: {
        show: function () {
          $vue.$store.commit("setVerticalSidebarDrawer", false);
          setTimeout(() => {
            tour.getCurrentStep().hide();
            tour.next();
          }, 3000);
        },
      },
      title: "Preencha os dados do evento",
      text: "Campos obrigatórios: Nome do evento, data e hora de início e fim e a imagem capa.",
      scopeUris: ["/admin/organization/parties/create"],
    },
    {
      attachTo: { element: "#btn-save-party", on: "top" },
      cancelIcon: { enabled: false },
      scopeUris: ["/admin/organization/parties/create"],
      when: {
        show: function () {
          const currentStep = tour.getCurrentStep();
          setTimeout(() => {
            currentStep.hide();
            window.scrollTo(0, 0);
          }, 3000);
          $vue.$root.$once("party-created", () => {
            currentStep.hide();
            tour.next();
            window.scrollTo(0, 0);
          });
        },
      },

      text: "Depois clique em criar evento.",
    },
    {
      attachTo: { element: "#container-party-summary", on: "bottom" },
      cancelIcon: { enabled: true },
      beforeShowPromise: () => sleep(500),
      scopeUris: ["/admin/organization/parties/create"],
      when: {
        show: function () {
          $vue.$root.$once("create-party-step:Privacy", tour.next);
        },
      },

      title: "Seu evento foi criado com sucesso!",
      text: "Agora você pode definir as configurações do evento ou terminar a configuração depois.",
    },
    {
      attachTo: { element: "#container-party-Privacy", on: "bottom" },
      cancelIcon: { enabled: true },
      beforeShowPromise: () => sleep(1000),
      scopeUris: ["/admin/organization/parties/create"],
      when: {
        show: function () {
          $vue.$root.$once("create-party-step:Location", tour.next);
        },
      },

      title: "Defina a privacidade do evento",
    },
    {
      attachTo: { element: "#container-party-Location", on: "bottom" },
      cancelIcon: { enabled: true },
      beforeShowPromise: () => sleep(1000),
      scopeUris: ["/admin/organization/parties/create"],
      when: {
        show: function () {
          $vue.$root.$once("create-party-step:AgeGroup", tour.next);
        },
      },

      title: "Defina a localização do evento",
      text: 'Utilize a barra de busca para pesquisar por nome de lugares ou endereços.<br><br>Você também pode definir se o local está "Revelado", caso esteja, o endereço exato será mostrado, caso contrário, apenas a cidade e estado serão mostrados.',
    },
    {
      attachTo: { element: "#container-party-AgeGroup", on: "bottom" },
      cancelIcon: { enabled: true },
      beforeShowPromise: () => sleep(1000),
      scopeUris: ["/admin/organization/parties/create"],
      when: {
        show: function () {
          $vue.$root.$once("create-party-step:Entrance", tour.next);
        },
      },

      title: "Defina a faixa etária do evento",
      text: "Você pode definir a faixa etária do evento, e uma faixa etária de quem pode entrar acompanhado dos responsáveis.",
    },
    {
      attachTo: { element: "#container-party-Entrance", on: "bottom" },
      cancelIcon: { enabled: true },
      beforeShowPromise: () => sleep(1000),
      scopeUris: ["/admin/organization/parties/create"],
      when: {
        show: function () {
          $vue.$root.$once("create-party-step:Refund", tour.next);
        },
      },

      title: "Defina a regras da portaria do evento",
      text: "Defina quais documentos devem ser apresentados na portaria do evento e as normas de vestimenta.",
    },
    {
      title: "Defina as regras de reembolsos do evento",
      text: "Defina qual período após a compra e antes do evento o usuário pode ter sua solicitação de reembolso aprovada.",

      attachTo: { element: "#container-party-Refund", on: "bottom" },
      cancelIcon: { enabled: true },
      beforeShowPromise: () => sleep(1000),
      scopeUris: ["/admin/organization/parties/create"],
      when: {
        show: function () {
          $vue.$root.$once("create-party-step:done", tour.next);
        },
      },
    },
    {
      title: "Você terminou o guia de criação de evento!",
      text: "Seu evento está criado e configurado, agora você pode adicionar os setores e lotes ao evento para que ele fique ativo e disponível para venda.",
      attachTo: { element: "#container-party-done", on: "top" },
      cancelIcon: { enabled: true },
      beforeShowPromise: () => sleep(1000),
      scopeUris: ["/admin/organization/parties/create"],
      buttons: [
        {
          text: "Finalizar",
          primary: true,
          action: tour.complete,
        },
      ],
    },
  ],
};
